const loadModules = () => {

	async function handle() {

		if(document.querySelector('body.home')) {
			const home = await import('../components/home.js');
			const scroll = await import('../components/scroll-bottom.js');
			const count = await import('../components/count-up.js');
			const accessdirect = await import('../components/front-access-direct.js');
			home.default();
			scroll.default();
			count.default();
			accessdirect.default();

		}else{
			const children = await import('../components/children-pages.js');
			const slider = await import('../components/slider.js');
			children.default();
			slider.default();

		}

	}

	handle();
}

export default loadModules;