//Lottie
//import "../../node_modules/lottie-web/build/player/lottie_light.min.js"

//Conditional Import
import loadModules from "./src/load-modules.js";

// Swiper
import "../../node_modules/swiper/swiper-bundle.min.js"

// Component
import './components/go-up.js'
import './components/footer.js'
import './components/header.js'

import './components/iframe-video-responsive.js'
import './components/popup-search.js'
import './components/switch-image.js'
import './components/animLottie.js'

window.addEventListener('DOMContentLoaded', function() {

    //wait until images, links, fonts, stylesheets, and js is loaded
    window.addEventListener("load", function() {

        // Initialize...
        // console.log('DOM fully loaded and parsed');
        
        // Load optionnal modules asynchronously...
        loadModules();
   });

});