(function($) {

	'use strict';

	var init = function() {
		var searchBtn = $('.js-toggle-search-popup');
		var searchCloseBtn = $('.js-toggle-search-popup-close');

		searchBtn.on('click', toggleSearchPopup);
		searchCloseBtn.on('click', closeSearchPopup);
	};

	var closeSearchPopup = function(e) {
		$('html').removeClass('search-opened');
		$('body').removeClass('search-opened');
	}

	var toggleSearchPopup = function (e) {
		console.log('click')
		
		var popup = $('#popup-search');
		
		if(!$('body').hasClass('search-opened')) {


			if ($(document).height() > $(window).height()) {
				if(!$('html').hasClass('noscroll')) {
					var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
					$('html').addClass('noscroll').css('top',-scrollTop);
				}
				$('body').addClass('nav-opened').addClass('search-opened');
			}

			popup.find('form input[name="s"]').focus().select();

		} else {

			var scrollTop = parseInt($('html').css('top'));
			$('html').removeClass('noscroll');
			$('html,body').scrollTop(-scrollTop);
			$('body').removeClass('nav-opened').removeClass('search-opened');

		}
		
	};

	$(document).ready(function() {
		init();
	});

})(jQuery);
