(function($) {

	'use strict';

	var config = {
		menu_footer_collapse: null,
		menu_footer_open: true
	};

	var init = function() {
		var toggle_menu_buttons = $('.main-footer .js-footer-toggle');
		config['menu_footer_collapse'] = $('.main-footer .has-children .sub-menu, .main-footer .menu-item-has-children .sub-menu');

		// Fisrt hide footer nav
		manageResize();
        $(window).on('resize', debounce(manageResize, 250));

        toggle_menu_buttons.on('click', manageToggle);
	};

	var manageToggle = function(e) {
		e.preventDefault();
		var target = $(e.currentTarget);

		toggleFooterMenu(target);
	};

	var toggleFooterMenu = function(target, force) {
		var force = force || false;

		var parent = target.closest('li');

		target.toggleClass('opened');
		parent.toggleClass('opened');

		if(force === 'hide')
			target.hide();
		else if(force === 'show')
			target.show();
		else
			parent.children('.sub-menu').slideToggle();
	}


	var manageResize = function(e) {
	    if(window.innerWidth < 768) {
        	config['menu_footer_collapse'].each(function(index, el) {
        		toggleFooterMenu($(el), 'hide');
        	})
        	config['menu_footer_open'] = false;
	    } else {
	    	config['menu_footer_collapse'].each(function(index, el) {
        		toggleFooterMenu($(el), 'show');
        	})
        	config['menu_footer_open'] = true;
	    }
	};


	var debounce = function(func, wait, immediate) {
	    var timeout;
	    return function() {
	        var context = this, args = arguments;
	        var later = function() {
	            timeout = null;
	            if (!immediate) func.apply(context, args);
	        };
	    
	        var callNow = immediate && !timeout;
	        clearTimeout(timeout);
	        timeout = setTimeout(later, wait);
	        if (callNow) func.apply(context, args);
	    };
	};

	$(document).ready(function() {
		init();
	});

})(jQuery);
