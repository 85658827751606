(function($) {

	'use strict';

	let currentItem = 0;

	const init = function() {
		
		let switchLink = $('.js-switch-link');

		switchLink.on('mouseenter', setImage);
		switchLink.on('mouseleave', removeImage);
	};

	const setImage = function(e) {
		let link = $(e.currentTarget),
			dataItem = link.data('item'),
			wrapper = link.closest('.js-switch-wrapper'),
			image = wrapper.find('.js-switch-image').find('[data-item="' + dataItem + '"]'),
			currentImage = wrapper.find('.js-switch-image .active');

		currentItem = dataItem;
		currentImage.removeClass('active');
		image.addClass('active');
	};

	const removeImage = function(e) {
		let link = $(e.currentTarget),
			dataItem = link.data('item');

		if(dataItem === currentItem) {
			let wrapper = link.closest('.js-switch-wrapper'),
				currentImage = wrapper.find('.js-switch-image .active'),
				defaultImage = wrapper.find('.js-switch-image').find('[data-item="0"]');

			currentImage.removeClass('active');
			defaultImage.addClass('active');
		}
	};

	$(document).ready(function() {
		init();
	});

})(jQuery);
