(function($) {

	'use strict';

	var init = function() {

		if($('.intro__anim').length) {

            myLottie.init()
            
        }
	};

    const myLottie = {

        renderer : (window.devicePixelRatio > 1) ? 'svg' : 'canvas',

        init : function() {

            const url = `https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.8.1/lottie_${this.renderer}.min.js`

            if(!document.querySelector(`script[src="${url}"]`)) {
                var script = document.createElement("script")
                script.src = url
                document.head.appendChild(script)
                script.onload = () => { this.parse() } 
            } else {
                this.parse()
            }
        },

        parse: function() {

            const animations = document.querySelectorAll('.intro__anim')

            animations.forEach((anim) => {

                this.load(anim)
                
            })

        },

        load : function(anim) {
    
            const container = anim.querySelector('.intro__anim__container')

            const myAnim = lottie.loadAnimation({
                container: container,
                renderer: this.renderer,
                loop: false,
                autoplay: false,
                path: container.dataset.lottie
            })

            var intersectionObserver = new IntersectionObserver(function(entries) {

                const [{ isIntersecting }] = entries;
    
                if (isIntersecting) {
    
                    myAnim.play()
    
                    intersectionObserver.disconnect();
                    
                }
    
            },
            {rootMargin: "0px 0px -200px 0px"})

            intersectionObserver.observe(anim)
    
        }

    }

	$(document).ready(function() {
		init();
	});

})(jQuery);