(function($) {

	'use strict'

	var init = function() {
		resizeIframe()
	}

	var resizeIframe = function() {
	    // Find all YouTube videos
	    var $allVideos = $(".video-wrapper iframe, .video-wrapper .wp-video")

		// Figure out and save aspect ratio for each video
		$allVideos.each(function() {

			$(this)
				.data('aspectRatio', this.height / this.width)
                .data('container', $(this).closest('.content_video__inner'))

				// and remove the hard coded width/height
				.removeAttr('height')
				.removeAttr('width')

		})

		// When the window is resized
		$(window).resize(function() {


			// Resize all videos according to their own aspect ratio
			$allVideos.each(function() {

				var $el = $(this)
                var newWidth = $el.data('container').width()
                $el
                    .width(newWidth)
                    .height(newWidth * $el.data('aspectRatio'))

			})

		// Kick off one resize to fix all videos on page load
		}).resize()
	}

	$(document).ready(function() {
		init()
	})

})(jQuery)
