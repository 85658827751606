(function($) {

	'use strict';

	var init = function() {
        goUp();
        hideShowGoUp();
	};

	var goUp = function(e) {
		
        $('#goUp').click(function(){
            
            $('html,body').animate({ scrollTop: 0 }, 500 );
            
        });
        
	};
    
    var hideShowGoUp = function(e) {
		
        $(window).scroll(function(){
            
            if($(window).scrollTop() > 200){
                $('#goUp').addClass('active');
            }else{
                $('#goUp').removeClass('active');
            }
            
        });
        
	};

	$(document).ready(function() {
		init();
	});

})(jQuery);
